
import {
    DxGantt,
    DxTasks,
    DxColumn,
    DxEditing,
} from 'devextreme-vue/gantt';
import DxSelectBox from 'devextreme-vue/select-box';

import UserChannelService from '../services/user-channel-service';
import UserService from '../services/user-service';
import ActivityService from '../services/activity-service';

export default {
    components: {
        DxGantt,
        DxTasks,
        DxColumn,
        DxEditing,
        DxSelectBox,
    },
    data() {
        return {
            dataSourceChannels: null,
            channel: null,
            assignee: null,
            dataSourceAssignee: null,
            tasks: null,
        };
    },
    created() {
        this.userChannelService = new UserChannelService();
        this.userService = new UserService();
        this.activityService = new ActivityService();
    },
    mounted() {
        this.usuarioId = localStorage.getItem("user-data");
        this.userChannelService.getUsuarioCanales(this.usuarioId).then((data) => {
            this.dataSourceChannels = data;
        });
        this.userService.getUsuarios().then((data) => {
            this.dataSourceAssignee = data;
        });
        this.tasks = [];
    },
    methods: {
        search() {
            this.tasks = [];
            if (this.channel != null && this.assignee != null) {
                this.activityService.getActividadesGantt(this.channel.id_canal, this.assignee.id_usuario).then(data => this.tasks = data);
            }
        },
        valueChangedChannel() {
            this.search();
        },
        valueChangedAssignee() {
            this.search();
        },
    },
};
